import { Buffer } from 'buffer';
import axios from 'axios';

import { getApiToken } from './api-token';

export interface FileData {
  filename: string;
  contentType: string;
  content: string;
  encoding: string;
}

interface MailData {
  mailSubject: string;
  mailBody: string;
  mailFromAddress: string;
  mailToAddress: string;
  mailBccAddress?: string;
  s3BucketName?: string;
  s3FileAttachmentPaths?: Array<string>;
  siteFileAttachments?: Array<FileData>;
}

export const sendMail = async (mailData: MailData): Promise<void> => {
  try {
    const apiToken = await getApiToken();

    const base64EncodedMailData = Buffer.from(
      JSON.stringify(mailData),
      'utf8',
    ).toString('base64');

    return axios.post(
      `${process.env.VUE_APP_API_URL}/mail`,
      {
        data: base64EncodedMailData,
      },
      {
        headers: { 'X-API-Token': apiToken },
      },
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error('An error occurred while sending the mail.');
  }
};
