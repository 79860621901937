import { AssistantContext } from '~/steps/steps';
import { ProductRequest } from '@/lib/submit/send-product-request';
import {
  PackageType,
  packagesProduction,
  packagesStaging,
} from '~/data/product-data';

export const getProductRequest = (
  assistantContext: AssistantContext,
  comment: string,
  productId: string,
  salutation?: string,
): ProductRequest => {
  const contactFormData = assistantContext.contactForm;

  return {
    city: contactFormData.city,
    comment,
    email: contactFormData.email,
    firstName: contactFormData.firstname,
    lastName: contactFormData.lastname,
    phone: contactFormData.phone,
    productId,
    productUrl: `${process.env.VUE_APP_SHOP_HOST}/detail/${productId}`,
    salutationDisplayName: (salutation as 'Herr' | 'Frau') ?? 'Keine Angabe',
    street: `${contactFormData.street} ${contactFormData.streetNumber}`,
    zipCode: contactFormData.zipcode,
  };
};

export const getProductIdFromUrl = () => {
  const DEFAULT_PRODUCT_ID = process.env.VUE_APP_SHOP_PRODUCT_ID;
  const url = new URL(window.location.href);

  return url.searchParams.get('product-id') ?? DEFAULT_PRODUCT_ID;
};

export const isPackageDistrictHeating = (packageId: string) => {
  const packages =
    process.env.VUE_APP_ASSISTANT_ENV === 'production'
      ? packagesProduction
      : packagesStaging;
  const currentPackage = packages.find(
    (item) => item.portalProductId === packageId,
  );

  return Boolean(
    currentPackage &&
      currentPackage.type === PackageType.PACKAGE_DISTRICT_HEATING,
  );
};
