import { GcSwProduct } from '@/lib/greenportal/load-products';

export enum Importance {
  UNIMPORTANT = 'unimportant',
  IMPORTANT = 'important',
  VERY_IMPORTANT = 'veryImportant',
}

export enum EnergySupplierImportance {
  UNIMPORTANT = 'unimportant',
  IMPORTANT = 'important',
}

// Brennwert basis
// Brennwert grün
// Brennwert grün+
// Pellet
// Wärmepumpe
// Wärmepumpe PV
// Fernwärme
export enum PackageType {
  PACKAGE_DISTRICT_HEATING = 'Package District Heating',
  PACKAGE_HEAT_OUTPUT_BASIC = 'Package Brennwert Basis',
  PACKAGE_HEAT_OUTPUT_GREEN = 'Package Brennwert Grün',
  PACKAGE_HEAT_OUTPUT_GREEN_PLUS = 'Package Brennwert Grün+',
  PACKAGE_HEAT_PUMP = 'Package Wärmepumpe',
  PACKAGE_HEAT_PUMP_PV = 'Package Wärmepumpe PV',
  PACKAGE_PELLET = 'Package Pellet',
}

export interface Package {
  portalProductId: string;
  portalProductName: string;
  swProduct?: GcSwProduct;
  filter: {
    independenceFromAnEnergySupplier: EnergySupplierImportance[];
    regenerativePart: Importance[];
  };
  type?: PackageType;
}

export const packagesStaging: Package[] = [
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.UNIMPORTANT],
      regenerativePart: [Importance.UNIMPORTANT],
    },
    // https://kassel-staging.green-portal.de/admin#/sw/product/detail/ae238884bad14dbeaf2b006896cd09a2
    // https://kassel-staging.green-portal.de/Waermepaket-Brennwert-basis/W-1
    // Paket 1
    portalProductId: 'ae238884bad14dbeaf2b006896cd09a2',
    portalProductName: 'Wärmepaket Brennwert basis',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.UNIMPORTANT],
      regenerativePart: [Importance.IMPORTANT, Importance.VERY_IMPORTANT],
    },
    // https://kassel-staging.green-portal.de/admin#/sw/product/detail/6aa6a38f44ce464488c71a14962ebc5a
    // https://kassel-staging.green-portal.de/Waermepaket-Brennwert-gruen/W-2
    // Paket 2
    portalProductId: '6aa6a38f44ce464488c71a14962ebc5a',
    portalProductName: 'Wärmepaket Brennwert grün',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.IMPORTANT],
      regenerativePart: [Importance.IMPORTANT],
    },
    // https://kassel-staging.green-portal.de/admin#/sw/product/detail/2f54066d409043cbbdbef86f0d5493e7
    // https://kassel-staging.green-portal.de/Waermepaket-Brennwert-gruen/W-3
    // Paket 3
    portalProductId: '2f54066d409043cbbdbef86f0d5493e7',
    portalProductName: 'Wärmepaket Brennwert grün+',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.UNIMPORTANT],
      regenerativePart: [Importance.IMPORTANT],
    },
    // https://kassel-staging.green-portal.de/admin#/sw/product/detail/8bc5b13e73f048bea5409505a40b36fd
    // https://kassel-staging.green-portal.de/Waermepaket-Fernwaerme/W-4
    // Paket 4
    portalProductId: '8bc5b13e73f048bea5409505a40b36fd',
    portalProductName: 'Wärmepaket Fernwärme',
    type: PackageType.PACKAGE_DISTRICT_HEATING,
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.IMPORTANT],
      regenerativePart: [Importance.VERY_IMPORTANT],
    },
    // KasselerWärmeService Smart Pellet
    // https://kassel-staging.green-portal.de/admin#/sw/product/detail/9482626fa28e41179d9ee405fa1b585f
    // https://kassel-staging.green-portal.de/Waermepaket-Pellet/W-5
    // Paket 5
    portalProductId: '9482626fa28e41179d9ee405fa1b585f',
    portalProductName: 'Wärmepaket Pellet',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.IMPORTANT],
      regenerativePart: [Importance.IMPORTANT],
    },
    // https://kassel-staging.green-portal.de/admin#/sw/product/detail/e012b63de6da475386d2fd6c7dfe2f78
    // https://kassel-staging.green-portal.de/Waermepaket-Waermepumpe/W-6
    // Paket 6
    portalProductId: 'e012b63de6da475386d2fd6c7dfe2f78',
    portalProductName: 'Wärmepaket Wärmepumpe',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.IMPORTANT],
      regenerativePart: [Importance.VERY_IMPORTANT],
    },
    // https://kassel-staging.green-portal.de/admin#/sw/product/detail/f908e6a8810d4a15944143756562ae95
    // https://kassel-staging.green-portal.de/Waermepaket-Waermepumpe-PV/W-7
    // Paket 7
    portalProductId: 'f908e6a8810d4a15944143756562ae95',
    portalProductName: 'Wärmepaket Wärmepumpe PV',
  },
];

export const packagesProduction: Package[] = [
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.UNIMPORTANT],
      regenerativePart: [Importance.UNIMPORTANT],
    },
    // https://shop.sw-kassel.de/admin#/sw/product/detail/0593d18c0c5c45089bd7159fd6291201
    // Paket 1
    portalProductId: '0593d18c0c5c45089bd7159fd6291201',
    portalProductName: 'Wärmepaket Brennwert basis',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.UNIMPORTANT],
      regenerativePart: [Importance.IMPORTANT, Importance.VERY_IMPORTANT],
    },
    // https://shop.sw-kassel.de/admin#/sw/product/detail/7348e1b6bc4e4a3db6c32d60f9d2ed84
    // Paket 2
    portalProductId: '7348e1b6bc4e4a3db6c32d60f9d2ed84',
    portalProductName: 'Wärmepaket Brennwert grün',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.IMPORTANT],
      regenerativePart: [Importance.IMPORTANT],
    },
    // https://shop.sw-kassel.de/admin#/sw/product/detail/bcc1d8f282c24ca1afa4b2c2152a8fa7
    // Paket 3
    portalProductId: 'bcc1d8f282c24ca1afa4b2c2152a8fa7',
    portalProductName: 'Wärmepaket Brennwert grün+',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.UNIMPORTANT],
      regenerativePart: [Importance.IMPORTANT],
    },
    // https://shop.sw-kassel.de/admin#/sw/product/detail/1376adf441e64bd0ba24d22a6e61e26b
    // Paket 4
    portalProductId: '1376adf441e64bd0ba24d22a6e61e26b',
    portalProductName: 'Wärmepaket Fernwärme',
    type: PackageType.PACKAGE_DISTRICT_HEATING,
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.IMPORTANT],
      regenerativePart: [Importance.VERY_IMPORTANT],
    },
    // KasselerWärmeService Smart Pellet
    // https://shop.sw-kassel.de/admin#/sw/product/detail/bfc0a09e8895427b925b8f30be7693d2
    // Paket 5
    portalProductId: 'bfc0a09e8895427b925b8f30be7693d2',
    portalProductName: 'Wärmepaket Pellet',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.IMPORTANT],
      regenerativePart: [Importance.IMPORTANT],
    },
    // https://shop.sw-kassel.de/admin#/sw/product/detail/065f8678ae0a4e6ca624107fdc141a8c
    // Paket 6
    portalProductId: '065f8678ae0a4e6ca624107fdc141a8c',
    portalProductName: 'Wärmepaket Wärmepumpe',
  },
  {
    filter: {
      independenceFromAnEnergySupplier: [EnergySupplierImportance.IMPORTANT],
      regenerativePart: [Importance.VERY_IMPORTANT],
    },
    // https://shop.sw-kassel.de/admin#/sw/product/detail/49adb56f8ee4400a9af6aba2f941402e
    // Paket 7
    portalProductId: '49adb56f8ee4400a9af6aba2f941402e',
    portalProductName: 'Wärmepaket Wärmepumpe PV',
  },
];
