export type ZipCode = number;

export const zipCodesKasselArea: ZipCode[] = [
  34117, 34125, 34119, 34121, 34125, 34123, 34134, 34125, 34117, 34121, 34119,
  34233, 34355, 34117, 34127, 34123, 34125, 34117, 34127, 34117, 34127, 34117,
  34127, 34125, 34127, 34125, 34121, 34123, 34123, 34195, 34114, 34194, 34197,
  34106, 34196, 34109, 34113, 34110, 34105, 34108, 34111, 34115, 34198, 34112,
  34134, 34132, 34130, 34131, 34128, 34266, 34128, 34123, 34233, 34246, 34246,
  34128, 34130, 34233, 34233, 34132, 34131, 34246, 34246, 34277, 34132, 34134,
  34246, 34253, 34253, 34260, 34132, 34355, 34233, 34266, 34132, 34131, 34123,
  34127, 34225, 34233, 34233, 34123, 34292, 34253, 34233, 34277, 34277, 34355,
  34314, 34314, 34355, 34314, 34225, 34292, 34314, 34260, 34246, 34225, 34355,
  34225, 34314, 34292, 34225, 34277, 34265, 34260, 34233, 34355, 34320, 34270,
  34225, 34260, 34355, 34225, 34355, 34393, 34320, 34346, 34302, 34355, 34233,
  34219, 34317, 34376, 34295, 34317, 34379, 34295, 34376, 34329, 34329, 34270,
  34302, 34379, 34289, 34355, 34355, 34302, 34393, 34302, 34270, 34329, 34295,
  34225, 34587, 34295, 34302, 34346, 34320, 34317, 34379, 34270, 34379, 34302,
  34270, 34281, 34302, 34376, 34327, 34587, 34289, 34305, 34320, 34298, 34393,
  34327, 34393, 34305, 34289, 34298, 34327, 34270, 34298, 34346, 34305, 34281,
  34327, 34289, 34393, 34587, 34587, 34281, 34305, 34346, 37235, 34289, 34305,
  34212, 34379, 34346, 37235, 34281, 34369, 34466, 34298, 34587, 34587, 34212,
  34281, 34346, 34308, 34308, 34289, 34369, 34369, 34379, 34289, 34466, 34305,
  34587, 34587, 34359, 34587, 37217, 34281, 34308, 34359, 34212, 34212, 34212,
  34346, 34466, 34289, 34308, 34346, 34212, 34346, 34587, 34281, 34466, 34359,
  34209, 34466, 34396, 37235, 34308, 37217, 34346, 34369, 34466, 34346, 34212,
  37217, 34587, 37235, 34560, 34346, 34560, 37247, 34587, 37247, 34587, 34560,
  34466, 37127, 34369, 34466, 34369, 37127, 37235, 34311, 37127, 34212, 34396,
  37247, 34286, 34587, 37217, 34479, 37216, 34311, 34560, 34286, 34590, 34369,
  37235, 34479, 37218, 34323, 37127, 37127, 34560, 34587, 34466, 37217, 34587,
  34560, 34286, 34311, 37217, 34396, 34396, 37213, 34346, 34590, 34479, 34587,
  34286, 37235, 37235, 37217, 34369, 37216, 34590, 34560, 34323, 37235, 34286,
  34286, 37127, 34396, 34286, 37247, 34369, 34323, 34311, 37247, 34323, 37247,
  34323, 37127, 34311, 34323, 34560, 34466, 34396, 34396, 34396, 34590, 37124,
  34471, 37218, 34466, 37235, 37215, 34388, 34576, 34346, 34326, 34590, 34560,
  37235, 34590, 34576, 37217, 37218, 34479, 34326, 37127, 34479, 34466, 37247,
  34326, 37127, 37127, 37127, 34466, 34388, 34414, 34388, 34286, 34323, 37235,
  34590, 37133, 34590, 34388, 34576, 37124, 34590, 37235, 37127, 34590, 37127,
  34326, 34286, 34388, 34434, 37242, 34286, 34326, 37215, 34454, 34471, 37249,
  34388, 37214, 37127, 34549, 34414, 34434, 34326, 34434, 34286, 34399, 34576,
  34560, 34414, 37284, 34582, 34414, 34286, 34593, 37242, 34593, 34549, 34576,
  34576, 34549, 37170, 34537, 37139, 37127, 34593, 34560, 34582, 34454, 37284,
  37249, 37249, 37139, 34399, 34576, 34326, 37297, 34388, 34399, 34576, 34537,
  37249, 34582, 34399, 34326, 34471, 34414, 34549, 37214, 37242, 34582, 34434,
  34286, 34576, 36211, 37124, 37124, 37214, 34434, 37133, 34454, 34513, 36211,
  37124, 37133, 37249, 34414, 34399, 37284, 34576, 34582, 34576, 37127, 34549,
  34513, 37249, 36211, 34414, 37297, 37284, 37284, 37249, 36211, 37124, 34434,
  34549, 37290, 34593, 34576, 37284, 34471, 37242, 34549, 37297, 34582, 34399,
  34454, 37290, 34576, 37284, 37133, 37170, 37124, 34576, 34582, 37284, 37139,
  37194, 37290, 36211, 34454, 36211, 34414, 34549, 34388, 37318, 37284, 37242,
  34549, 37133, 37318, 37124, 37194, 34513, 34593, 37284, 36211, 37284, 37124,
  34582, 34582, 37133, 37242, 37194, 34399, 34454, 37133, 34582, 36199, 36211,
  37318, 34593, 37079, 34549, 34516, 34513, 34582, 34593, 36211, 34385, 34596,
  34434, 34576, 37170, 37242, 37284, 34414, 37290, 34471, 36211, 37139, 34537,
  37290, 34385, 34535, 37242, 37133, 34471, 34576, 37170, 34596, 34513, 36211,
  34582, 37139, 36199, 37194, 34576, 37688, 34593, 37297, 34593, 37124, 34576,
  34593, 37318, 37079, 37284, 37194, 34414, 34537, 34414, 34434, 37242, 37688,
  34576, 37079, 34593, 37133, 37318, 34474, 37688, 37318, 34582, 37290, 34582,
  34477, 34477, 34454, 34576, 36219, 34454, 37124, 34549, 37290, 37170, 34474,
  37318, 37133, 37688, 36199, 36205, 37133, 34621, 37290, 34454, 36205, 34474,
  36199, 37287, 34439, 34599, 37133, 34414, 37139, 37170, 37139, 34454, 37688,
  34513, 34582, 34549, 34596, 34576, 36199, 36179, 36199, 34621, 37284, 34434,
  34537, 34593, 37079, 36251, 34593, 34434, 34414, 37242, 37284, 36205, 37079,
  34582, 34537, 34621, 37133, 34596, 34537, 34513, 36199, 34513, 34621, 37318,
  34454, 37318, 34439, 36219, 37170, 37269, 36199, 37697, 37120, 36205, 36251,
  34621, 37318, 37318, 36199, 37170, 37318, 37318, 34576, 34621, 37194, 34621,
  36219, 34599, 34621, 37318, 34477, 34434, 34596, 34537, 36205, 37170, 34593,
  37269, 34593, 37269, 37181, 34439, 37170, 34537, 34513, 34599, 36251, 34599,
  34477, 37079, 37081, 37079, 37170, 34621, 37130, 34474, 37133, 37194, 34513,
  34599, 36199, 37318, 37318, 34439, 37688, 37318, 37269, 34576, 34549, 34414,
  36205, 36179, 37085, 37083, 37181, 37318, 34516, 37170, 34621, 37083, 34439,
  34599, 37276, 37269, 34477, 37287, 37120, 34414, 37130, 34632, 37318, 36199,
  34599, 37688, 37318, 34434, 37688, 37120, 34474, 34593, 37181, 37287, 37287,
  37170, 37318, 36179, 37170, 37170, 34439, 37170, 34439, 37697, 34549, 36219,
  37194, 37318, 37170, 34621, 34454, 34593, 37287, 37276, 37181, 34497, 37691,
  34497, 37318, 37130, 34599, 36251, 34537, 34621, 36251, 37170, 36205, 36205,
  37269, 33034, 36286, 36219, 34621, 36205, 37688, 37318, 37081, 37075, 37096,
  37083, 37099, 37095, 37085, 37097, 37073, 37098, 37100, 37079, 33034, 34414,
  36179, 37267, 37181, 37688, 37318, 37318, 37077, 37075, 36214, 34537, 37276,
  37318, 36251, 37318, 34516, 34537, 37130, 34431, 34516, 34474, 34613, 37276,
  34454, 34516, 34439, 37318, 34439, 37130, 34537, 37318, 33034, 37318, 36205,
  37181, 34431, 34474, 36286, 34639, 36179, 37308, 36179, 37318, 34477, 36205,
  37688, 36286, 36286, 34477, 34621, 36179, 37075, 36251, 37276, 37287, 37170,
  36179, 34537, 36214, 34599, 34474, 36251, 36286, 36179, 37308, 36214, 34439,
  36179, 37688, 37318, 37296, 34613, 34621, 34477, 37176, 34639, 34439, 37181,
  36179, 35114, 37077, 34516, 37181, 37318, 37120, 37130, 34516, 36286, 34632,
  37671, 37308, 34439, 37318, 37671, 37176, 37130, 37130, 34516, 33034, 34626,
  37276, 34613, 34632, 37308, 34519, 34621, 35114, 36251, 34474, 34431, 37130,
  36286, 33034, 34632, 37077, 34516, 36179, 37699, 36251, 37308, 37318, 37181,
  37308, 37130, 34516, 37308, 37308, 36177, 36179, 33034, 36205, 37120, 34632,
  37269, 36179, 37308, 37176, 37181, 34497, 34494, 34495, 36214, 34431, 36205,
  37077, 33034, 37176, 34497, 35114, 37671, 34626, 33034, 36286, 33034, 37181,
  35114, 35114, 37276, 37176, 37269, 37308, 34474, 37181, 36251, 37308, 34613,
  34613, 37186, 34516, 34611, 34516, 35110, 37296, 34431, 36208, 37308, 37186,
  37308, 34516, 36286, 34630, 34613, 34431, 34497, 36280, 37296, 34613, 37691,
  36251, 37308, 37186, 37130, 37186, 34513, 34613, 36217, 37136, 36251, 37308,
  36251, 33165, 36214, 34626, 34630, 33034, 37120, 37130, 36275, 35114, 36275,
  36205, 35110, 37130, 34628, 36251, 34630, 37308, 37276, 37130, 34431, 36217,
  36251, 37130, 37176, 37308, 34626, 37186,
];
